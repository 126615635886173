import React from 'react';

import Router from './routes/Router';

// SASS Importing
import './App.scss';

export const isMobile = () => {
  const ua = navigator.userAgent;
  return /Android|Mobi/i.test(ua) || window.innerWidth < 991;
};

export const App = (props) => {
  return (
    <>
      <Router />
    </>
  );
};

export default App;
