import React from 'react';

const Loader = () => {
  return (
    <div id="loader-wrapper" className="pageContainer">
      <div id="loader">
        {/* TODO: FOOTPRINT ANIMATION */}
        <div>
          {/* <div className="laoderFooterPrintLoading" /> */}
          <svg
            id="Layer_1"
            data-name="Layer 1"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 4585.06 765.85"
            className="loaderSvg"
          >
            <polyline
              points="171 534.55 462.69 534.55 461.63 664.51 466.34 83 83 669.09"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <path
              d="M1204.64,665.53V100.13h142.25a141.18,141.18,0,0,1,141.19,141.18h0A141.18,141.18,0,0,1,1346.89,382.5H1204.64"
              transform="translate(0 -1.03)"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <path
              d="M707.26,665.53V100.13H849.51A141.18,141.18,0,0,1,990.7,241.31h0A141.18,141.18,0,0,1,849.51,382.5H707.26"
              transform="translate(0 -1.03)"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <circle
              cx="1977.28"
              cy="383.41"
              r="338.29"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="85"
              className="strokeAnim"
            />
            <path
              d="M2951.47,596.71a282.43,282.43,0,0,1-185.28,68.82c-156.09,0-282.62-125.85-282.62-281.09s126.53-281.1,282.62-281.1a282.4,282.4,0,0,1,183.72,67.49"
              transform="translate(0 -1.03)"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <line
              x1="1871.42"
              y1="344.4"
              x2="1871.42"
              y2="305.88"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="60"
              className="strokeAnim"
            />
            <polyline
              points="2097.62 297.62 2049.11 344.4 2121.93 376.05"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="50"
              className="strokeAnim"
            />
            <path
              d="M1962.8,513.73s116.48,11.47,171.51-62.82"
              transform="translate(0 -1.03)"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="50"
              className="strokeAnim"
            />
            <polyline
              points="3188.63 682.85 3188.63 83.97 3188.63 381.47 3311.53 381.47 3489.47 83.97 3311.53 381.47 3489.47 682.85"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <polyline
              points="3993.93 664.51 3731.61 664.51 3731.61 381.47 3993.93 381.47 3731.61 381.47 3731.61 102.31 3993.93 102.31"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
            <polyline
              points="4353.39 682.85 4353.39 102.31 4204.89 102.31 4502.06 102.31"
              fill="none"
              stroke="transparent"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="166"
              className="strokeAnim"
            />
          </svg>
        </div>
      </div>

      <div className="loader-section section-left"></div>
      {/* <div className="loader-section section-right"></div> */}
    </div>
  );
};

export default Loader;
